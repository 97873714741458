import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
import About from 'pages/About';
import Services from 'pages/Services';
import Contact from 'pages/Contact';
import BaseLayout from 'layouts/BaseLayout';
import UnderConstruction from 'pages/UnderConstruction';
import './App.css';

const App: React.FC = () => {
  const isProduction = window.location.hostname !== 'localhost';

  return (
    <Router>
      <BaseLayout>
        <Routes>
          {isProduction ? (
            <Route path="*" element={<UnderConstruction />} />
          ) : (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
            </>
          )}
        </Routes>
      </BaseLayout>
    </Router>
  );
};

export default App;
