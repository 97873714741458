import { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import 'assets/styles/Main.scss';
import App from './App';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);

const Main = (): ReactElement => <App />;

root.render(<Main />);
