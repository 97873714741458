import { useEffect, useRef } from 'react';
import { Typography } from 'antd';

const { Title, Text } = Typography;

const UnderConstruction = () => {
  const cursorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (cursorRef.current) {
        cursorRef.current.style.setProperty('--x', `${event.clientX}px`);
        cursorRef.current.style.setProperty('--y', `${event.clientY}px`);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <>
      <h1 className="mainTitle">JAIVIN</h1>
      <Title style={{ color: 'white', textAlign: 'center', marginTop: '15%' }}>
        Our website is under construction
      </Title>
      <Text
        style={{
          color: 'white',
          textAlign: 'center',
          display: 'block',
          marginTop: '10px'
        }}
      >
        We are working hard to bring you the best experience. Stay tuned!
      </Text>
      <div className="cursor" ref={cursorRef}></div>
    </>
  );
};

export default UnderConstruction;
