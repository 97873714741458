import { ReactElement, FC } from 'react';

interface BaseLayoutProps {
  children: ReactElement;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return <main>{children}</main>;
};

export default BaseLayout;
